import { Application } from "@hotwired/stimulus"
import "@hotwired/turbo-rails"
import "@/utils/fontawesome"

import TimeZoneController from "@/controllers/time_zone_controller"
import PasswordVisibilityController from "@stimulus-components/password-visibility"
import DropdownController from "@stimulus-components/dropdown"
import NotificationController from "@stimulus-components/notification"

import "../stylesheets/application.scss"

const application = Application.start()

application.register("notification", NotificationController)
application.register("dropdown", DropdownController)
application.register("password-visibility", PasswordVisibilityController)
application.register("time-zone", TimeZoneController)
